<template>
  <div>
    <v-card>
      <v-card-title>Exames </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="filters.type"
                          :items="[
                            { text: 'Público', value: 'public' },
                            { text: 'Privado', value: 'private' },
                          ]"
                          label="Tipo"
                        >
                        </v-select
                      ></v-col>
                    </v-row>
                    <v-row v-if="filters.type === 'public'">
                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.PartnershipId"
                          :items="partnerships"
                          label="Empresa / Órgão conveniado"
                        >
                        </v-select
                      ></v-col>
                    </v-row>

                    <v-row v-if="filters.type === 'private'">
                      <v-col>
                        <v-select
                          :loading="loadingCompanyBranches"
                          label="Filial/Matriz:"
                          v-model="filters.companyBranch"
                          :items="filteredCompanyBranches"
                          item-value="id"
                          item-text="companyName"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.patient"
                        >
                        </v-text-field
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadExams()">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon left>mdi-cloud-download</v-icon> BAIXAR EXAMES</v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="downloadAllFilesSeparately">
                  <v-list-item-title>Arquivos separados</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadFilesInCompressedFolder">
                  <v-list-item-title
                    >Compactados em uma pasta</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <v-chip small :color="urgencyForTime(item.createdAt).color">{{
              urgencyForTime(item.createdAt).text
            }}</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Ver exame" @click="openReportScreen(item)">
              <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn icon title="Baixar exame" @click="downloadFile(item.id)"
              ><v-icon>mdi-cloud-download</v-icon></v-btn
            >
            <v-btn icon title="Editar exame" @click="openUpdateScreen(item.id)"
              ><v-icon>mdi-file-document-edit</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>

    <v-dialog max-width="300" persistent v-model="downloadExamsDialog">
      <v-card>
        <v-card-title>Baixando arquivos...</v-card-title>
        <v-card-text>
          <div class="pa-5 d-flex justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import jsZip from "jszip";
import { saveAs } from "file-saver";
import { baseUrl } from "@/config/config.json";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      loading: false,
      downloadExamsDialog: false,
      loadingExamPartnerships: false,
      loadingCompanyBranches: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data do exame", value: "dateOfRealization" },
        { text: "Recebido em", value: "submissionDate" },
        { text: "Espera", value: "createdAt" },
        { text: "Paciente", value: "patientName" },
        {
          text: "Modalidade",
          value: "examModeName",
        },
        { text: "Status", value: "examStatusName", sortable: false },
        { text: "Urgência", value: "urgentExamStatusName" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      companyBranches: [],
      partnerships: [],
      filters: {
        patient: null,
        startDate: null,
        endDate: null,
        type: "private",
        companyBranch: null,
        PartnershipId: null,
      },
      timer: setInterval(() => {
        this.loadExams();
      }, 60000),
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          page: currentPage,
          type: "private",
          examStatus: ["pending", "requested-resend"],
          ...this.filters,
        };

        const response = await this.$axios.get("/exams", { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteExamFromDataTable(examId) {
      const index = this.items.findIndex((item) => item.id == examId);

      if (index != -1) {
        let copyOfItems = [...this.items];

        copyOfItems.splice(index, 1);

        this.items = copyOfItems;
      }
    },
    async deleteExam(examId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente deletar o exame selecionado?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/people/${examId}`);

        this.deleteExamFromDataTable(examId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    openInsertScreen() {
      this.$router.push("/exames/cadastrar");
    },
    openUpdateScreen(examId) {
      this.$router.push(`/exames/${examId}/editar`);
    },
    openReportScreen(examData) {
      // console.log(status);
      if (examData.MedicalReport) {
        this.$router.push(`/exames/${examData.id}/laudo/editar`);
      } else {
        this.$router.push(`/exames/${examData.id}/laudo/cadastrar`);
      }
    },
    clearFilters() {
      this.filters = {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
        type: null,
        companyBranch: null,
      };
      this.currentPage = 1;
      this.loadExams();
    },
    setResponseData(data) {
      const copyOfData = JSON.parse(JSON.stringify(data));

      // console.log(copyOfData);

      this.items = copyOfData.map((item) => {
        item.patientName = item.Patient.Person.fullName;

        item.dateOfRealization = this.$moment(item.dateOfRealization)
          .add("3", "hours")
          .format("DD/MM/YYYY [ás] HH:mm");

        item.submissionDate = this.$moment(item.createdAt).format(
          "DD/MM/YYYY [ás] HH:mm"
        );

        item.examStatusName = this.examStatusName(item.examStatus);

        item.urgentExamStatusName = this.urgentExamStatusName(
          item.urgentExamStatus
        );

        item.examName =
          item.type === "public"
            ? item.ExamReference.Procedure.name
            : item.SchedulingProcedure.Procedure.name;

        item.examModeName =
          item.type === "public"
            ? item.ExamReference.ExamMode.name
            : item.SchedulingProcedure.Procedure.ExamReference.ExamMode.name;

        item.examSubspecialtyName =
          item.type === "public"
            ? item.ExamReference.ExamSubspecialty.name
            : item.SchedulingProcedure.Procedure.ExamReference.ExamSubspecialty
                .name;

        // console.log(copyOfData);
        // console.log(this.items);

        return item;
      });
    },

    examStatusName(status) {
      if (status === "pending") return "Pendente";
      else if (status === "requested-resend") return "Reenvio solicitado";
      else if (status === "completed") return "Finalizado";
    },
    urgentExamStatusName(status) {
      if (status === "no-urgency") return "Sem urgência";
      else if (status === "intermediate-urgency")
        return "Urgência intermediária";
      else if (status === "urgent") return "Urgente";
    },

    downloadFile(examId) {
      window.open(`${baseUrl}/exams/${examId}/download?redirect=1`, "_blank");
    },

    downloadAllFilesSeparately() {
      const exams = [...this.items];
      for (let i = 0; i < exams.length; i++) this.downloadFile(exams[i].id);
    },
    async downloadFilesInCompressedFolder() {
      try {
        this.downloadExamsDialog = true;

        const zip = new jsZip();
        const exams = [...this.items];

        for (let i = 0; i < exams.length; i++) {
          let responseUrl = await this.$axios.get(
            `/exams/${exams[i].id}/download`
          );

          let response = await axios.get(responseUrl.data.fileUrl, {
            crossdomain: true,
            responseType: "blob",
          });

          var aux = new Blob([response.data]);

          zip.file(`exame-${exams[i].id}.zip`, aux);
        }

        const allFiles = await zip.generateAsync({ type: "blob" });

        saveAs(
          allFiles,
          `exames(${this.$moment().format("YYYY-MM-DD-HH:mm:ss")}).zip`
        );

        this.downloadExamsDialog = false;
      } catch (error) {
        console.log(error);
        this.downloadExamsDialog = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    urgencyForTime(date) {
      const now = this.$moment().utcOffset("-0300");

      const submissionDate = this.$moment(date).utcOffset("-0300");

      const difference = now.diff(submissionDate, "minutes");

      if (difference < 10) {
        return {
          color: "success",
          text: `${difference} minutos`,
        };
      } else if (difference >= 10 && difference < 20) {
        return {
          color: "warning",
          text: `${difference} minutos`,
        };
      } else {
        return {
          color: "error",
          text: `${difference} minutos`,
        };
      }
    },

    async loadPartnerships() {
      try {
        this.loadingExamPartnerships = true;

        const url = `/partnerships`;

        const res = await this.$axios.get(url);

        this.setPartnerships(res.data);

        this.loadingExamPartnerships = false;
      } catch (error) {
        this.loadingExamPartnerships = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setPartnerships(data) {
      const copyOfData = [...data];

      this.partnerships = copyOfData.map((p) => ({
        text: p.Person.fantasyName,
        value: p.id,
      }));
    },

    async loadCompanyBranches() {
      try {
        this.loadingCompanyBranches = true;

        const url = `/company-branches`;

        const res = await this.$axios.get(url);

        this.setCompanyBranches(res.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loadingCompanyBranches = false;
      }
    },
    setCompanyBranches(data) {
      const copyOfData = [...data];

      this.companyBranches = copyOfData;
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    filteredCompanyBranches() {
      const { companyBranches, userHasBranchRestriction } = this.userData;

      return userHasBranchRestriction
        ? this.companyBranches.filter((b) => companyBranches.includes(b.id))
        : this.companyBranches;
    },
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  created() {
    this.loadExams();
    this.loadPartnerships();
    this.loadCompanyBranches();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
